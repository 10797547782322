import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Elastic/ElasticClientInstrumentation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Login/Wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/El.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Button.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Flex.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Alert.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Center.module.css");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/FieldSet.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Heading.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Input.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Link.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/List.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/LoginBox.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Search.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Switch.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Text.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Textarea.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Spinner.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/VisuallyHidden.module.scss");
