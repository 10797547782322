import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/folder.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/home/collections-empty.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/home/dashboard-empty.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/home/notes-empty.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/home/tools-empty.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/library/geography.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/library/history.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/library/literacy.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/library/math.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/library/science.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/related.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/teacher-only.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-assessment.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-doc.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-file.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-img.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-mp3.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-mp4.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-pdf.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-ppt.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-weblink.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail-xls.svg");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/public/thumbnail/generic-thumbnail.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Elastic/ElasticClientErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Elastic/ElasticClientInstrumentation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Error/ErrorBoundaryWarning.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/FeaturedCollections.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/Collections.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/Dashboard.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/MathToolsIcon.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["MathToolsIcon"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/MathToolsIcon.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/OnboardingTeacherModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecentlyViewedDate"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/RecentlyViewedDate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageWithFallback"] */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/ImageWithFallback.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/PageWrapper.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/ThumbnailContainer.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Thumbnail.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Ribbon.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/RecentlyViewedRow.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/Sections.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/core/src/components/Home/Notes.module.scss");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/LineClampedText.tsx");
;
import(/* webpackMode: "eager" */ "/Users/erik/repos/nelson/next-core/workspaces/ui/src/lib/Switch.tsx");
